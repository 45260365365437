import { useMemo } from 'react'
import { MyFelfelHost } from './remote-url'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Keycloak: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _keycloak: any,
    }
}

export const keycloakRef = {
    current: createKeycloak()
}

window._keycloak = keycloakRef

/**
 * Create a new instance of Keycloak without running init function.
 */
function createKeycloak() {
    const KeycloakClass = (window as any).Keycloak
    return new KeycloakClass(process.env.REACT_APP_KEYCLOAK_CONFIG_URL)
}

/**
 * Initialize the Keycloak object and return the authentication result.
 */
export function CheckSSO(): Promise<{ keycloak: any, authenticated: boolean }> {
    const keycloak = keycloakRef.current

    return new Promise<{ keycloak: any, authenticated: boolean }>((resolve, reject) => {
        keycloak
            .init({ onLoad: 'check-sso', checkLoginIframe: false })
            .success((authenticated: boolean) => {
                resolve({ keycloak, authenticated })
            })
            .error(() => reject('Error in initializing keycloak'))
    })
}

export function IsAuthenticated(): boolean {
    return keycloakRef.current.authenticated
}

export function CreateLoginUrl() {
    return keycloakRef.current.createLoginUrl({
        redirectUri: MyFelfelHost('')
    })
}

export function CreateRegisterUrl() {
    return keycloakRef.current.createRegisterUrl({
        redirectUri: MyFelfelHost('')
    })
}

/**
 * Return currency depending on iss domain
 */
export function CurrencyFromAuth() {
    if (keycloakRef.current.idTokenParsed.iss.includes('.ch')) return 'CHF'
    return 'USD'
}
/**
 * Return memoized CurrencyFromAuth to use in a React Component or Hook
 */
export function useCurrency() {
    return useMemo(CurrencyFromAuth, [])
}