import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'intersection-observer/intersection-observer.js'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { CheckSSO } from './app/utils/keycloak-authentication'

Sentry.init({
    dsn: 'https://711a957b695e40ca820211dad449bb4b@o37218.ingest.sentry.io/6046883',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
});

/**
 * This iife will try to do keycloak authentication ONLY IF USER IS AUTHENTICATED:
 * It will load the LaunchApp module and execute the default function.
 * @see LaunchApp
 */
(async () => {
    console.table?.(process.env)

    try {
        await CheckSSO()
        
        const launchAppModule = await import('./app/scripts/launch-app')
        launchAppModule.default()
    } catch (e) {
        console.error(e)
    }
})()
